import moment from 'moment';
import { TFunction } from 'i18next';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';

export const MS_IN_SEC = 1000;
export const MS_IN_MIN = 60 * MS_IN_SEC;
export const MS_IN_HOUR = 60 * MS_IN_MIN;
export const SEC_IN_HOUR = 60 * 60;
export const SEC_IN_MIN = 60;
export const MS_IN_DAY = 24 * MS_IN_HOUR;
export const MIN_IN_HOUR = 60;

export const getDateTime = (date: number): string => moment(date).format('HH:mm');
export const getTimeRelativeStartDay = (dayMs: number): string => moment().startOf('day').add(dayMs).format('HH:mm');

export const getHours = (ms: number): number => Math.floor(ms / MS_IN_HOUR);
export const getMinutes = (ms: number): number => Math.floor(ms % MS_IN_HOUR) / MS_IN_MIN;

export const getDateFromDate = (date: Date): string => moment(date).format('YYYY-MM-DD');

export const getStartDayFromDateZero = (date: string): string => `${date}T00:00:00.000Z`;
export const getEndDayFromDateZero = (date: string): string => `${date}T23:59:00.000Z`;

export const getStartDayFromDate = (date: string): string => moment(date).startOf('day').format();
export const getEndDayFromDate = (date: string): string => moment(date).endOf('day').format();

export const patchMidnightRelativeTime = (relativeTime: number): number => {
    if (relativeTime === 24 * MS_IN_HOUR) {
        return relativeTime - MS_IN_MIN;
    }

    return relativeTime;
};

export const getISOFromDateAndTime = (date: Date, time: number) => {
    const clearedDate = date.toISOString().slice(0, 10);

    return moment(clearedDate).add(time, 'ms').format();
};

export const getYear = (isoDate: string): number => {
    return moment(isoDate).get('year');
};

export const getISOFutureDate = (next: number, unit: 'year' | 'month' | 'week' | 'day'): string => {
    return moment().add(next, unit).format();
};

export const getISOPastDate = (next: number, unit: 'year' | 'month' | 'week' | 'day'): string => {
    return moment().subtract(next, unit).format();
};

export const getDateFromISO = (date: string | null | undefined): string => (date ? date.slice(0, 10) : '');

export const checkMidnightTime = (time: string): string => {
    if (time === '00:00') {
        return '23:59';
    }

    return time;
};

type ParsedDiffT = { days: number; hours: number; minutes: number; seconds: number };

export const parseDiff = (diffMS: number): ParsedDiffT => {
    const seconds = Math.floor(diffMS / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    return {
        days: Math.floor(days),
        hours: hours % 24,
        minutes: minutes % 60,
        seconds: seconds % 60,
    };
};

export const formatDiff = (t: TFunction, parsedDiff: ParsedDiffT): string => {
    const formattedComponents = [];

    if (parsedDiff.days) {
        formattedComponents.push(t('common:short-time-components.days', { days: parsedDiff.days }));
    }

    if (parsedDiff.hours) {
        formattedComponents.push(t('common:short-time-components.hours', { hours: parsedDiff.hours }));
    }

    if (parsedDiff.minutes) {
        formattedComponents.push(t('common:short-time-components.minutes', { minutes: parsedDiff.minutes }));
    }

    return formattedComponents.join(' ');
};

export const getDayDiff = (dateA: Date | string, dateB: Date | string): number => {
    const diff = getSignedDayDiff(dateA, dateB);
    return Math.abs(diff);
};

export const getSignedDayDiff = (dateA: Date | string, dateB: Date | string): number => {
    const momentDateA = moment(dateA).startOf('day');
    const momentDateB = moment(dateB).startOf('day');
    return momentDateA.diff(momentDateB, 'day');
};

export const clearDateTimezone = (date: string | null | undefined): string | null => {
    if (!date) {
        return null;
    }

    return date.slice(0, 19);
};

export const replaceTimeInISO = (sourceISO: string, time: string) => {
    const sourceISOParts = sourceISO.split('');

    return sourceISOParts.slice(0, 11).join('') + time + sourceISOParts.slice(16).join('');
};

export const formatDate = (format: string, date: Date | number | string | null | undefined): string | null => {
    if (!date) {
        return null;
    }

    return moment(date).format(format);
};

export const getRelativeStartDayTimeWindow = (
    timeWindow: TimeWindowT | null,
    date: string | null,
): TimeWindowT | null => {
    if (!timeWindow || !date || !isNumber(timeWindow[0]) || !isNumber(timeWindow[1])) {
        return timeWindow;
    }

    const timestamp = moment(date).startOf('day').valueOf();

    return [Math.max(timeWindow[0] - timestamp, 0), Math.min(timeWindow[1] - timestamp, 24 * MS_IN_HOUR)];
};

export const checkIsToday = (date: string | null | undefined): boolean => {
    if (!date) {
        return false;
    }

    const isToday = moment(date).isSame(Date.now(), 'day');

    return isToday;
};

export const checkIsSameDay = (dateA: string | null | undefined, dateB: string | null | undefined): boolean => {
    if (!dateA) {
        return false;
    }

    return moment(dateA).isSame(dateB, 'day');
};

export const checkIsSameYear = (
    dateA: string | Date | null | undefined,
    dateB: string | Date | null | undefined,
): boolean => {
    if (!dateA) {
        return false;
    }

    return moment(dateA).isSame(dateB, 'year');
};

export const checkIsYesterday = (date: string | null | undefined): boolean => {
    if (!date) {
        return false;
    }

    const isYesterday = moment(date).isSame(Date.now() - MS_IN_DAY, 'day');

    return isYesterday;
};

export const checkIsTomorrow = (date: string | null | undefined): boolean => {
    if (!date) {
        return false;
    }

    const isTomorrow = moment(date).isSame(Date.now() + MS_IN_DAY, 'day');

    return isTomorrow;
};

export const formatHumanReadableDate = (
    t: TFunction,
    format: string,
    date: string | null | undefined,
): string | null => {
    if (!date) {
        return null;
    }

    if (checkIsToday(date)) {
        return t('common:dates.today');
    }

    if (checkIsYesterday(date)) {
        return t('common:dates.yesterday');
    }

    if (checkIsTomorrow(date)) {
        return t('common:dates.tommorow');
    }

    return moment(date).format(format);
};

export const formatTimeInterval = (
    from: string | undefined | null,
    to: string | undefined | null,
    dateFormat?: string | undefined | null,
): string | null => {
    if (!from || !to) {
        return null;
    }

    const preparedFrom = clearDateTimezone(from);
    const preparedTo = clearDateTimezone(to);

    const momentFromDate = moment(preparedFrom);
    const momentToDate = moment(preparedTo);
    if (!momentFromDate.isValid() || !momentToDate.isValid()) {
        return null;
    }

    const isSameYear = momentFromDate.isSame(preparedTo, 'year');
    const defaultDateFormat = isSameYear ? 'D MMM' : 'D MMM YYYY';

    const fromDate = momentFromDate.format(dateFormat || defaultDateFormat);
    const fromTime = momentFromDate.format('HH:mm');

    const toDate = momentToDate.format(dateFormat || defaultDateFormat);
    const toTime = momentToDate.format('HH:mm');

    const isSameDay = momentFromDate.isSame(preparedTo, 'day');
    if (isSameDay) {
        return `${fromDate}, ${fromTime} - ${toTime}`;
    }

    return `${fromDate} ${fromTime} - ${toDate} ${toTime}`;
};

export const getTimeLeft = (targetDate: string | null | undefined): number | null => {
    if (!targetDate) {
        return null;
    }

    const now = moment().valueOf();
    const targetDateTime = moment(targetDate).valueOf();

    return targetDateTime - now;
};

export const getCurrentYear = (): number => {
    return new Date().getFullYear();
};

export const checkIsPastDate = (timestamp: number | null): boolean => {
    if (!isNumber(timestamp)) {
        return false;
    }

    return timestamp < Date.now();
};

export const parseDateString = (date: string | null | undefined): number | null => {
    if (!date) {
        return null;
    }

    return moment.parseZone(date).valueOf();
};

export const parseISOString = (date: string | null | undefined): Date | null => {
    if (!date) {
        return null;
    }

    return moment(date).toDate();
};

export const formatTime = (timestamp: number): string => {
    const diff = Date.now() - timestamp;
    const shouldShowDay = diff > MS_IN_DAY;

    const momentFromDate = moment(new Date(timestamp));
    const time = momentFromDate.format('HH:mm');

    if (shouldShowDay) {
        const date = momentFromDate.format('D MMM');
        return `${time} ${date}`;
    }

    return time;
};

export const formatISOTime = (isoDate: string): string => {
    const momentFromDate = moment(isoDate);

    const time = momentFromDate.format('HH:mm');

    const shouldShowDay = !momentFromDate.isSame(Date.now(), 'day');
    if (shouldShowDay) {
        const date = momentFromDate.format('D MMM');
        return `${time} ${date}`;
    }

    return time;
};

export const formatTimer = (lostMs: number): string => {
    const date = new Date(0);
    date.setMilliseconds(lostMs);
    return date.toISOString().substring(14, 19);
};

export const getCurrentTimeOffsetMin = (): number => {
    return moment().utcOffset();
};

export const floorTimeBy = (time: number, period: number): number => {
    return Math.floor(time / period) * period;
};

export const ceilTimeBy = (time: number, period: number): number => {
    return floorTimeBy(time, period) + period;
};

export const checkIsValidISO = (value: string | null | undefined): boolean => {
    if (!value) {
        return false;
    }

    return moment(value).isValid();
};

export const checkIsInDateRange = (
    from: string | undefined | null,
    to: string | undefined | null,
    date: string | undefined | null,
): boolean => {
    if (!date || !from || !to) {
        return true;
    }

    return from <= date && date <= to;
};

export const getDatesBetweenDates = (range: Array<string | null | undefined>): Array<string> => {
    if (range.length !== 2 || range.some((value) => !checkIsValidISO(value))) {
        return [];
    }

    const [startDate, endDate] = range;
    if (!endDate) {
        return [];
    }

    const dates = [];

    let currentDate = moment(startDate);

    while (currentDate.format('YYYY-MM-DD') <= endDate) {
        dates.push(currentDate.format('YYYY-MM-DD'));

        currentDate = currentDate.clone().add(1, 'days');
    }

    return dates;
};

export const addHoursToDate = (date: string | null | undefined, hours: number | null | undefined): string | null => {
    if (!date || !isNumber(hours)) {
        return null;
    }

    // @ts-expect-error _offset is internal field
    // eslint-disable-next-line no-underscore-dangle
    const originUtcOffset = moment().utcOffset(date)._offset;

    return moment(date).add(hours, 'hours').utcOffset(originUtcOffset).format();
};

export const roundTimestamp = (timestamp: TimestampT, step: number): TimestampT => {
    return Math.ceil(timestamp / step) * step;
};

export const parseISOTimeZone = (isoDate: string | null | undefined): number | null => {
    if (!isoDate) {
        return null;
    }

    return moment.parseZone(isoDate).utcOffset();
};

export const formatDateWithTimeZone = (
    isoDate: string | null | undefined,
    utcOffset: number | null | undefined,
    dateFormat: string,
): string | null => {
    if (!isoDate) {
        return null;
    }

    const momentObj = moment.parseZone(isoDate);
    if (isNumber(utcOffset)) {
        momentObj.utcOffset(utcOffset);
    }

    return momentObj.format(dateFormat);
};
export const formatTimeZone = (utcOffset: number | null | undefined): string | undefined => {
    if (!isNumber(utcOffset)) {
        return undefined;
    }

    return `UTC ${moment().utcOffset(utcOffset).format().slice(19, 25)}`;
};

export const setISODateMidday = (isoDate: string | null | undefined): string | null => {
    if (!isString(isoDate)) {
        return null;
    }

    return [isoDate.slice(0, 11), '12:00:00', isoDate.slice(19)].join('');
};

export const getMinISODate = (...isoDates: Array<ISODateTimeT | null | undefined>): ISODateTimeT | null => {
    if (!isoDates?.length) {
        return null;
    }

    let minISODate: ISODateTimeT | null = null;
    isoDates.forEach((isoDate) => {
        if (!isoDate) {
            return;
        }

        if (!minISODate) {
            minISODate = isoDate;
        }

        if (isoDate < minISODate) {
            minISODate = isoDate;
        }
    });

    return minISODate;
};

export const getMaxISODate = (...isoDates: Array<ISODateTimeT | null | undefined>): ISODateTimeT | null => {
    if (!isoDates?.length) {
        return null;
    }

    let maxISODate: ISODateTimeT | null = null;
    isoDates.forEach((isoDate) => {
        if (!isoDate) {
            return;
        }

        if (!maxISODate) {
            maxISODate = isoDate;
        }

        if (isoDate > maxISODate) {
            maxISODate = isoDate;
        }
    });

    return maxISODate;
};

export const convertSecToHours = (layoverSeconds: number): number => {
    return Math.ceil(layoverSeconds / SEC_IN_HOUR);
};

export const shiftMidnight = (value: string): string => {
    const momentDate = moment.parseZone(value);

    const isMidnight = momentDate.format('HH:mm') === '00:00';
    if (isMidnight) {
        return momentDate.subtract(1, 'minutes').format();
    }

    return value;
};
